'use client'
import React, { useState } from 'react'
import { map, sortBy, find, compact } from 'lodash'
import moment from 'moment-timezone'
import { ApolloError } from '@apollo/client'
import { useAtom } from 'jotai'

import {
    DepartmentDetailFragment,
    AdminAppointmentTypeDetailFragment,
} from '@docpace/shared-graphql/fragments'
import { ctxPracticeId } from '@docpace/shared-react-atoms'
import { AdminProviderDetailFragment } from '@docpace/shared-graphql/fragments'
import { useAdminPracticeBaseProvidersQuery, useAdminPracticeBaseQuery } from '@docpace/admin-react-apollo'

interface UseAdminPracticeBaseProps {
    practiceId?: string
    departmentId?: string
    providerId?: string
    skip?: boolean
}

export function useAdminPracticeBase(
    props?: UseAdminPracticeBaseProps
){
    const [fetchError, setFetchError] = useState<ApolloError | null>(null)
    const [ _ctxPracticeId ] = useAtom(ctxPracticeId)
    const practiceId = props?.practiceId ?? _ctxPracticeId
    const {
        loading: practiceLoading,
        data: { practice } = {},
        refetch: practiceBaseRefetch,
    } = useAdminPracticeBaseQuery({
        variables: {
            practiceId: practiceId ?? '',
            dayStart: moment().startOf('day').format('YYYY-MM-DD'),
            dayEnd: moment().startOf('day').format('YYYY-MM-DD'),
        },
        skip: !practiceId || !!fetchError || props?.skip,
        onError: setFetchError,
    })
    const {
        loading: providersLoading,
        data,
        refetch: providersBaseRefetch,
    } = useAdminPracticeBaseProvidersQuery({
        variables: {
            practiceId: practiceId ?? '',
            dayStart: moment().startOf('day').format('YYYY-MM-DD'),
            dayEnd: moment().startOf('day').format('YYYY-MM-DD'),
        },
        skip: !practiceId || !!fetchError || props?.skip,
        onError: setFetchError,
    })
    
    const rawProviders = data?.practice?.providers

    const {
        appointmentTypes: rawAppointmentTypes,
        departments: rawDepartments,
        providerDepartmentOptions,
    } = practice ?? {}

    const appointmentTypes: AdminAppointmentTypeDetailFragment[] = compact(
        sortBy(rawAppointmentTypes?.nodes ?? [], 'nameDisplay')
    )
    const departments: DepartmentDetailFragment[] = compact(
        sortBy(rawDepartments?.nodes ?? [], 'publicName')
    )
    const providers: AdminProviderDetailFragment[] = compact(
        sortBy(rawProviders?.nodes ?? [], 'displayName')
    )

    return {
        practice: practice ?? null,
        practiceId: practiceId ?? null,
        appointmentTypes,
        appointmentTypeIds: map(
            appointmentTypes,
            ({ appointmentTypeId }) => appointmentTypeId
        ),
        appointmentTypeById: (appointmentTypeId) =>
            find(appointmentTypes, { appointmentTypeId }) ?? null,
        departments,
        departmentById: (departmentId) =>
            find(departments, { departmentId }) ?? null,
        providers,
        providerById: (providerId) => find(providers, { providerId }) ?? null,
        providerDepartmentOptions:
            compact(providerDepartmentOptions?.nodes) ?? [],
        // isLoading: practiceLoading || jwtLoading,
        isLoading: practiceLoading || providersLoading,
        practiceBaseRefetch: async ()=>practiceBaseRefetch().then(()=>{}),
        providersBaseRefetch: async ()=>providersBaseRefetch().then(()=>{}),
    }
}

export default useAdminPracticeBase
