import React, { FC, useState } from 'react'
import { useAtom } from 'jotai'
import {
    find,
    filter,
    first,
    memoize,
    compact,
    uniq,
    map,
    reduce,
} from 'lodash'

import {
    TranslationType,
} from '@docpace/shared-ts-types/base/Translation'
import {
    TemplateType,
} from '@docpace/shared-ts-types/base/Template'
import {
    Language,
} from '@docpace/shared-ts-types/base/Language'
import { ctxPracticeId } from '@docpace/shared-react-atoms'
import { useGlobalTemplatesQuery, useGlobalTranslationsQuery, usePracticeTemplatesQuery, usePracticeTranslationsQuery } from '@docpace/manager-react-apollo'
import { useAdminCreateTranslationContentMutation, useAdminCreateTranslationMutation, useAdminDeleteTranslationContentMutation, useAdminDeleteTranslationMutation, useAdminUpdateTranslationContentMutation, useAdminUpdateTranslationMutation } from '@docpace/admin-react-apollo'

interface UsePracticeTemplatesTranslationsProps {
    translationId?: string
    selectedLanguage?: string
}


export function usePracticeTemplatesTranslations(
    props?: UsePracticeTemplatesTranslationsProps
){
    const {
        translationId,
        selectedLanguage = 'eng',
    } = props ?? {}
    const [ practiceId ] = useAtom(ctxPracticeId)
    // const [ isSubmitting, setIsSubmitting ] = useState<boolean>(false)

    const { data: globalTranslations, loading: globalTranslationsLoading } =
        useGlobalTranslationsQuery()
    const {
        data: practiceTranslationsData,
        loading: translationsLoading,
        refetch: refetchTranslations,
    } = usePracticeTranslationsQuery({
        variables: { practiceId },
        skip: !practiceId,
    })

    const translations = practiceTranslationsData?.translations?.nodes

    const { data: globalTemplates, loading: globalTemplatesLoading } =
        useGlobalTemplatesQuery()

    const { data, loading: templatesLoading } =
        usePracticeTemplatesQuery({
            variables: { practiceId },
            skip: !practiceId,
        })
    const templates = data?.templates
    const [createTranslation] = useAdminCreateTranslationMutation({
        onCompleted: () => {
            refetchTranslations()
        },
    })

    const [updateTranslation] = useAdminUpdateTranslationMutation()

    const [createTranslationContent] = useAdminCreateTranslationContentMutation({
        onCompleted: () => {
            refetchTranslations()
        },
    })

    const [updateTranslationContent] = useAdminUpdateTranslationContentMutation()
    const [deleteTranslationContent] = useAdminDeleteTranslationContentMutation()
    const [deleteTranslation] = useAdminDeleteTranslationMutation({
        onCompleted: ()=>{
            refetchTranslations()
        }
    })

    const _translations =
        compact(translations) ?? []
    const _globalTranslations =
        compact(globalTranslations?.translations?.nodes) ?? []
    const _templates = compact(templates?.nodes) ?? []
    const _globalTemplates = compact(globalTemplates?.templates?.nodes) ?? []

    const allTranslations = [..._globalTranslations, ..._translations]
    const allTemplates = [..._globalTemplates, ..._templates]

    const practiceSmsTranslations =
        compact(practiceTranslationsData?.practice?.smsTranslations?.nodes) ??
        []
    const globalSmsTranslations =
        compact(practiceTranslationsData?.smsTranslations?.nodes) ?? []
    const practiceFaqTranslations =
        compact(practiceTranslationsData?.practice?.faqTranslations?.nodes) ??
        []
    const globalFaqTranslations =
        compact(practiceTranslationsData?.faqTranslations?.nodes) ?? []
    const practiceContactTranslations =
        compact(
            practiceTranslationsData?.practice?.contactTranslations?.nodes
        ) ?? []
    const globalContactTranslations =
        compact(practiceTranslationsData?.contactTranslations?.nodes) ?? []
    const practiceDirectionsTranslations =
        compact(
            practiceTranslationsData?.practice?.directionsTranslations?.nodes
        ) ?? []
    const globalDirectionsTranslations =
        compact(practiceTranslationsData?.directionsTranslations?.nodes) ?? []
    const practiceLinkTranslations =
        compact(practiceTranslationsData?.practice?.linkTranslations?.nodes) ??
        []
    const globalLinkTranslations =
        compact(practiceTranslationsData?.linkTranslations?.nodes) ?? []

    const smsTranslation =
        find(practiceSmsTranslations, { translationId })
    const faqTranslation =
        find(practiceFaqTranslations, { translationId })
    const linkTranslation =
        find(practiceLinkTranslations, { translationId })
    const directionsTranslation =
        find(practiceDirectionsTranslations, { translationId })
    const contactTranslation =
        find(practiceContactTranslations, { translationId })
    const translation = first(
        compact([
            smsTranslation,
            faqTranslation,
            linkTranslation,
            directionsTranslation,
            contactTranslation,
        ])
    )

    const mem: any[] = []
    const practiceTranslationLanguages = uniq(
        reduce(
            [
                ...practiceSmsTranslations,
                ...practiceFaqTranslations,
                ...practiceContactTranslations,
                ...practiceDirectionsTranslations,
                ...practiceLinkTranslations,
            ],
            (mem, translation) => {
                return [
                    ...mem,
                    ...compact(
                        uniq(
                            map(
                                compact(
                                    translation.nonEnglishTranslationContents
                                        ?.nodes
                                ),
                                ({ languageId }) => languageId
                            )
                        )
                    ),
                ]
            },
            mem
        )
    )

    const englishContent = translation?.englishTranslationContents?.nodes?.[0]
    const nonEnglishContents = compact(
        translation?.nonEnglishTranslationContents?.nodes ?? []
    )

    const translationContent = find([englishContent, ...nonEnglishContents], {
        languageId: selectedLanguage,
    })

    return {
        // translationContent: translationContent,
        isLoading:
            templatesLoading ||
            translationsLoading ||
            globalTranslationsLoading ||
            globalTemplatesLoading,
        translation,
        smsTranslation,
        faqTranslation,
        linkTranslation,
        directionsTranslation,
        contactTranslation,
        translations,
        practiceTranslations: _translations,
        globalTranslations: _globalTranslations,
        practiceSmsTranslations,
        globalSmsTranslations,
        practiceFaqTranslations,
        globalFaqTranslations,
        practiceContactTranslations,
        globalContactTranslations,
        practiceDirectionsTranslations,
        globalDirectionsTranslations,
        practiceLinkTranslations,
        globalLinkTranslations,
        smsTranslations: filter(allTranslations, {
            translationType: TranslationType.SMS,
        }),
        templates: allTemplates,
        globalTemplates: _globalTemplates,
        practiceTemplates: _templates,
        vwrTemplates: filter(allTemplates, { templateType: TemplateType.VWR }),
        translationById: memoize(
            (translationId) =>
                find(_translations, { translationId }) ??
                find(_globalTranslations, { translationId })
        ),
        translationContentById: memoize((translationId, lang) => {
            const translation =
                find(_translations, { translationId }) ??
                find(_globalTranslations, { translationId })
            if (lang === Language.eng) {
                return translation?.englishTranslationContents?.nodes?.[0]
            } else {
                return find(translation?.nonEnglishTranslationContents?.nodes, {
                    languageId: lang,
                })
            }
        }),
        createTranslation,
        updateTranslation,
        createTranslationContent,
        updateTranslationContent,
        deleteTranslationContent,
        deleteTranslation,
        practiceTranslationLanguages,
        englishContent,
        nonEnglishContents,
        translationContent,
    }
}

export default usePracticeTemplatesTranslations
