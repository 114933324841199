'use client'
import {
    DepartmentIcon,
    PracticeIcon,
    ProviderIcon,
    ManagerIcon,
    StatsIcon,
    SettingsIcon,
    DeviceIcon,
    AppointmentIcon,
    AppointmentTypeIcon,
    TranslationIcon,
    PracticePatientLocationIcon,
    WarningIcon,
} from '@docpace/shared-react-icons'
import { TranslationType } from '@docpace/shared-ts-types/base/Translation'
import { keyBy, map, includes, keys, isArray } from 'lodash'

const emptyParams: any = {}

export class PartialRoute<T extends { [key: string]: string | number }> {
    constructor(public readonly path: string, public readonly icon?: any) {
        this.path = path
        this.icon = icon
    }

    href(query: T = emptyParams): { pathname: string; query: T } {
        return {
            pathname: this.path,
            query,
        }
    }

    hrefNext(query: T = emptyParams): string {
        let nextQuery = {}
        let nextPathname = this.path
        map(query, (val: any, key)=>{
            let keyPath = `[${key}]`
            if(includes(nextPathname, keyPath)){
                nextPathname = nextPathname.replace(keyPath, isArray(val) ? val?.join(',') : val)
            } else {
                nextQuery[keyPath] = val
            }
        })
        return nextPathname + (keys(nextQuery).length > 0 ? `?` + new URLSearchParams(nextQuery).toString() : '')
    }
}

const adminRoutes = Object.freeze({
    Signin: new PartialRoute('/auth'),
    Signout: new PartialRoute('/auth/signout'),
    Home: new PartialRoute('/practice'),
    AdminDisabledPractices: new PartialRoute('/practice/disabled'),
    AdminAdminIndex: new PartialRoute('/admin'),
    AdminAdminDetail: new PartialRoute<{ adminId: string }>('/admin/[adminId]'),
    AdminDemoIndex: new PartialRoute('/demo'),
    AdminDeviceIndex: new PartialRoute('/device'),
    AdminSessionIndex: new PartialRoute('/session'),
    MonitorStateDurations: new PartialRoute('/monitor/durations'),
    Warnings: new PartialRoute(
        '/warnings',
        WarningIcon
    ),
    PracticeHome: new PartialRoute<{ practiceId: string }>(
        '/practice/[practiceId]',
        PracticeIcon
    ),
    PracticeReportsHome: new PartialRoute<{ practiceId: string }>(
        '/practice/[practiceId]/reports',
        PracticeIcon
    ),
    PracticeTableauReportDetail: new PartialRoute<{ practiceId: string, practiceTableauReportId: number }>(
        '/practice/[practiceId]/reports/t/[practiceTableauReportId]',
        PracticeIcon
    ),
    PracticeAppointments: new PartialRoute<{ practiceId: string }>(
        '/practice/[practiceId]/appointment',
        AppointmentIcon
    ),
    PracticeAppointmentTypes: new PartialRoute<{ practiceId: string }>(
        '/practice/[practiceId]/appointmentType',
        AppointmentTypeIcon
    ),
    PracticeAppointmentTypeDetail: new PartialRoute<{
        practiceId: string
        appointmentTypeId: string
        translationId?: string
    }>(
        '/practice/[practiceId]/appointmentType/[appointmentTypeId]',
        AppointmentTypeIcon
    ),
    // PracticeAppointmentTypeMessaging: new PartialRoute<{ practiceId: string, appointmentTypeId: string, translationId?: string }>(
    //   '/practice/[practiceId]/appointmentType/[appointmentTypeId]/messaging',
    //   AppointmentTypeIcon
    // ),
    // PracticeAppointmentTypeVwr: new PartialRoute<{ practiceId: string, appointmentTypeId: string, translationId?: string }>(
    //   '/practice/[practiceId]/appointmentType/[appointmentTypeId]/vwr',
    //   AppointmentTypeIcon
    // ),
    PracticeDevices: new PartialRoute<{ practiceId: string }>(
        '/practice/[practiceId]/device',
        DeviceIcon
    ),
    PracticeProviders: new PartialRoute<{ practiceId: string }>(
        '/practice/[practiceId]/provider',
        ProviderIcon
    ),
    PracticeDepartments: new PartialRoute<{ practiceId: string }>(
        '/practice/[practiceId]/department',
        DepartmentIcon
    ),
    PracticeTranslations: new PartialRoute<{ practiceId: string }>(
        '/practice/[practiceId]/translation',
        TranslationIcon
    ),
    PracticeTranslationDetail: new PartialRoute<{
        practiceId: string
        translationId: string
        translationType: TranslationType
        edit?: string
        delete?: string
    }>(
        '/practice/[practiceId]/translation/[translationId]/[translationType]',
        TranslationIcon
    ),
    PracticeManagers: new PartialRoute<{ practiceId: string }>(
        '/practice/[practiceId]/manager',
        ManagerIcon
    ),
    PracticeDeletedManagers: new PartialRoute<{ practiceId: string }>(
        '/practice/[practiceId]/manager/deleted',
        ManagerIcon
    ),
    PracticeConfigure: new PartialRoute<{ practiceId: string }>(
        '/admin/practice/[practiceId]/configure',
        SettingsIcon
    ),
    PracticeConfigurePdos: new PartialRoute<{
        practiceId: string
        appointmentTypeId?: string
        providerId?: string
        departmentId?: string
    }>('/practice/[practiceId]/configure/pdos', SettingsIcon),
    PracticeConfigureDelayConfigs: new PartialRoute<{
        practiceId: string
        appointmentTypeId?: string
        providerId?: string
        departmentId?: string
    }>('/practice/[practiceId]/configure/delayConfigs', SettingsIcon),
    PracticeConfigureDepartmentGooglePlaces: new PartialRoute<{
        practiceId: string
        appointmentTypeId?: string
        providerId?: string
        departmentId?: string
    }>('/practice/[practiceId]/configure/departmentGooglePlaces', SettingsIcon),
    PracticeConfigureLocations: new PartialRoute<{
        practiceId: string
    }>('/practice/[practiceId]/configure/locations', PracticePatientLocationIcon),
    PracticeAppointmentFetching: new PartialRoute<{ practiceId: string }>(
        '/practice/[practiceId]/configure/appointmentFetching',
        SettingsIcon
    ),
    PracticeSlugEdit: new PartialRoute<{ practiceId: string, slugId: string }>(
        '/practice/[practiceId]/slug/[slugId]/edit',
        SettingsIcon
    ),
    ProviderDetail: new PartialRoute<{
        providerId: string
        practiceId: string
    }>('/practice/[practiceId]/provider/[providerId]', ProviderIcon),
    ProviderAppointments: new PartialRoute<{
        providerId: string
        practiceId: string
        date?: string
    }>(
        '/practice/[practiceId]/provider/[providerId]/appointment',
        ProviderIcon
    ),
    ProviderConversions: new PartialRoute<{
        practiceId: string
    }>(
        '/practice/[practiceId]/provider/conversions',
        ProviderIcon
    ),
    ProviderDepartments: new PartialRoute<{
        providerId: string
        practiceId: string
    }>(
        '/practice/[practiceId]/provider/[providerId]/departments',
        ProviderIcon
    ),
    ProviderAppointmentTypes: new PartialRoute<{
        providerId: string
        practiceId: string
    }>(
        '/practice/[practiceId]/provider/[providerId]/appointmentTypes',
        ProviderIcon
    ),
    ProviderReportProviderDay: new PartialRoute<{
        providerId: string
        practiceId: string
    }>(
        '/practice/[practiceId]/provider/[providerId]/report/providerDay',
        ProviderIcon
    ),
    DepartmentDetail: new PartialRoute<{
        departmentId: string
        practiceId: string
    }>('/practice/[practiceId]/department/[departmentId]', DepartmentIcon),
    DepartmentProviders: new PartialRoute<{
        departmentId: string
        practiceId: string
    }>(
        '/practice/[practiceId]/department/[departmentId]/providers',
        ProviderIcon
    ),
    DepartmentAppointmentTypes: new PartialRoute<{
        departmentId: string
        practiceId: string
    }>(
        '/practice/[practiceId]/department/[departmentId]/appointmentTypes',
        ProviderIcon
    ),
    ManagerDetail: new PartialRoute<{ managerId: string; practiceId: string }>(
        '/practice/[practiceId]/manager/[managerId]',
        ManagerIcon
    ),
    ManagerPermissions: new PartialRoute<{
        managerId: string
        practiceId: string
    }>('/practice/[practiceId]/manager/[managerId]/permissions', ManagerIcon),
})

export default adminRoutes
